import { createRouter, createWebHashHistory } from 'vue-router'

const constantRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/detail/:id',
    name: 'GoDetail',
    component: () => import('@/views/detail/index.vue'),
    meta: {
      title: '详情页'
    }
  },
  {
    path: '/createArticle',
    name: 'CreateArticle',
    component: () => import('@/views/createArticle/index.vue'),
    meta: {
      title: '编辑文章'
    }
  },
  {
    path: '/archive',
    name: 'Archive',
    component: () => import('@/views/archive/index.vue'),
    meta: {
      title: '归档'
    }
  },
  {
    path: '/friendslink',
    name: 'Friendslink',
    component: () => import('@/views/friendslink/index.vue'),
    meta: {
      title: '友链'
    }
  },
  {
    path: '/reward',
    name: 'Reward',
    component: () => import('@/views/reward/index.vue'),
    meta: {
      title: '赞赏'
    }
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('@/views/message/index.vue'),
    meta: {
      title: '留言板'
    }
  },
  {
    path: '/aboutme',
    name: 'Aboutme',
    component: () => import('@/views/aboutme/index.vue'),
    meta: {
      title: '关于'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '注册登录'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user/index.vue'),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/likeCollect',
    name: 'LikeCollect',
    component: () => import('@/views/likeCollect/index.vue'),
    meta: {
      title: '点赞/收藏'
    }
  },
  {
    path: '/:catchAll(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  base: '/admin2.0',
  scrollBehavior: () => ({
    y: 0
  }),
  history: createWebHashHistory(),
  routes: constantRoutes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
