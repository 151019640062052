<template>
  <img :src="src" :onerror="errorAvatar" />
</template>
<script>
import { getStaticResourceUrl } from '@/utils/commonutil'
export default {
  name: 'HeadImg',
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      errorAvatar: 'this.onerror=null;this.src="' + getStaticResourceUrl('@/../../assets/img/tou.jpg') + '"'
    }
  }
}
</script>
