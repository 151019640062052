import { createStore } from 'vuex'

import app from '@/store/modules/app'
import common from '@/store/modules/common'
import user from '@/store/modules/user'

import getters from '@/store/getters'

const store = createStore({
  modules: {
    app: app,
    common: common,
    user: user
  },
  getters: getters
})

export default store
