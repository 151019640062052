// 全局，返回成功的错误码
export const SUCCESS_CODE = '1000000'

// 全局，用户没有登录成功的错误码
export const NO_LOGIN = '1000025'

// 全局默认基础路径
export const BASE_PATH = '/v1/blog/front'

// 后端地址，如登录等
export const BASE_PATH_BACK = '/v1/blog'
