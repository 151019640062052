import request from './index'
import { BASE_PATH_BACK } from '@/constant/common'
const PATH = BASE_PATH_BACK + '/user'

function login(data) {
  return request({
    url: `${PATH}/login`,
    method: 'post',
    data
  })
}

function logout() {
  return request({
    url: `${PATH}/logout`,
    method: 'get'
  })
}

function getInfo() {
  return request({
    url: `${PATH}/getUserInfo`,
    method: 'get'
  })
}

function getWebBlogUser() {
  return request({
    url: `${PATH}/getWebBlogUser`,
    method: 'get'
  })
}
function edit(data) {
  return request({
    url: `${PATH}/edit`,
    method: 'post',
    data
  })
}

export default {
  login,
  logout,
  getInfo,
  edit,
  getWebBlogUser
}
