import request from './index'
import { BASE_PATH } from '@/constant/common'
const PATH = BASE_PATH + '/articleCate'

function getList(params) {
  return request({
    url: `${PATH}/getList`,
    method: 'get',
    params
  })
}

function getAllList(params) {
  return request({
    url: `${PATH}/list`,
    method: 'get',
    params
  })
}

function getInfo(params) {
  return request({
    url: `${PATH}/getInfo`,
    method: 'get',
    params
  })
}

function add(data) {
  return request({
    url: `${PATH}/add`,
    method: 'post',
    data
  })
}

function edit(data) {
  return request({
    url: `${PATH}/edit`,
    method: 'post',
    data
  })
}

export default {
  getList,
  getAllList,
  getInfo,
  add,
  edit
}
