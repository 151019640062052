import request from './index'
import { BASE_PATH, BASE_PATH_BACK } from '@/constant/common'
const PATH = BASE_PATH + '/comment'
const PATH_BACK = BASE_PATH_BACK + '/comment'

function getList(params) {
  return request({
    url: `${PATH}/page`,
    method: 'post',
    data: params
  })
}

function add(data) {
  return request({
    url: `${PATH_BACK}/add`,
    method: 'post',
    data
  })
}

function getInfo(params) {
  return request({
    url: `${PATH}/getInfo`,
    method: 'get',
    params
  })
}

function getComment(params) {
  return request({
    url: `${PATH}/page`,
    method: 'post',
    data: params
  })
}
export default {
  getList,
  getInfo,
  add,
  getComment: getComment
}
