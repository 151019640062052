const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.accessToken,
  avatar: (state) => state.user.avatar,
  userName: (state) => state.user.userName,
  userId: (state) => state.user.userId,
  role: (state) => state.user.role,
  userInfo: (state) => state.userInfo,
  status: (state) => state.user.status,
  haslogin: (state) => state.user.haslogin,
  activeCateList: (state) => state.common.activeCateList
}
export default getters
