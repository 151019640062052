import request from './index'
import { BASE_PATH, BASE_PATH_BACK } from '@/constant/common'
const PATH = BASE_PATH + '/love'
const PATH_BACK = BASE_PATH_BACK + '/love'

function add(data) {
  return request({
    url: `${PATH_BACK}/add`,
    method: 'post',
    data
  })
}

export default {
  add
}
